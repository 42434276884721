<template>
  <div id="fbw" style="margin-bottom:70px;">
<!--    <img src="../../../assets/fbw/banner1.png" style="width:100%;margin-bottom:20px;">-->

    <div class="box" style="margin-top:350px;">
      <div class="box-top"></div>
      <div class="box-title">AI评估更靠谱，让商标注册成功率提升80%</div>
      <Field label-width="100px" v-model.trim="brandName" label="商 标 名 称" placeholder="请输入要评估的商标名称" @input="checkWords"
             @change="check('brandName')"/>
      <Field style="width:100%;" label-width="100px" v-model.trim="serviceName" label="商品或服务名称"
             placeholder="请输入商品或服务，比如面包" readonly @click="goSearch"/>
      <div class="button" @click="go">点击免费试用</div>
      <div class="tips">
        已有 <span style="color:#218CF5">1829</span>家企业成功注册商标
      </div>
    </div>
        <div class="tips2">
          以上服务由知依昇为您提供
        </div>
    <div>
      <div style="text-align: center;margin:10px; font-weight: bold;font-size:16px;color:#fff;">政府认可</div>
      <div style="font-size:14px;text-align: center;color:#fff;">该服务模块于2021年上线杭州市滨江区知识产权公共服务平台。</div>
    </div>
    <consult></consult>
  </div>
</template>

<script>
import {Field, Toast} from 'vant';
import {forbid, insertClickLog, signLogin} from "@/api/api";
import store from "@/store";
import Consult from "@/components/consult2";
import {debounce} from "lodash";

export default {
  name: "yqlHome",
  data() {
    return {
      companyName: '',
      brandName: '',
      serviceName: ''
    }
  },
  methods: {
    async go() {
      if (this.brandName && this.serviceName) {
        if(!this.check('brandName')) return false
        if(this.brandName){
          let result = await this.forbid()
          if(!result) return false
        }
        this.$router.push(`/fbw2/recommend?brandName=${this.brandName}&serviceName=${this.serviceName}`)
      }else if(!this.brandName){
        Toast('商标名称不能为空')
        return false
      }else if(!this.serviceName){
        Toast('商品或服务名称不能为空')
        return false
      }
    },
    goSearch() {
      this.$router.push(`/fbw2/search?value=${this.serviceName}`)
    },
    async signLogin() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      });
      let {userMobile, accessKey, userNickname, sign} = this.$route.query;
      if (!sign) {
        Toast.clear();
        return false
      }

      let res = await signLogin({
        userMobile, accessKey, userNickname, sign: decodeURIComponent(sign)
      })
      if (res.code === 200) {
        let userInfo = {...res.data,userMobile, accessKey, userNickname, sign}
        this.$store.commit('setUserInfo', userInfo)
        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        console.log(this.$store.state.userInfo);
        //记录日志
        await this.insertClickLog(res.data)
        Toast.clear();
      }else{
        this.$router.go(-1)
      }
      console.log(res);
    },
    async insertClickLog({userCode, userName}) {
      let res = await insertClickLog({
        userCode,
        userName,
        channels: 'fbw2',
        urlName: 'FBW静默登录'
      })
    },
    check(key) {
      const regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,
          regNum = /\D/g
      if(!regNum.test(this[key])){
        Toast("不能为纯数字");
        return false;
      }
      if (regEn.test(this[key]) || regCn.test(this[key])) {
        Toast("不能包含特殊字符");
        return false;
      }
      return true
    },
    async forbid() {
      if(!this.brandName) return false
      let res = await forbid({
        text: this.brandName
      })
      if (res.code === 200 && res.data.forbid === 'True') {
        Toast(res.data.reason)
        return false
      }
      return true
    },
    checkWords: debounce(function () {
      this.forbid()
    }, 500)
  },
  mounted() {
    this.$store.commit('setOrigin',this.$route.meta.origin)
    if(this.$route.meta.origin === 'fbw'){
      if (!this.$store.state.userInfo || !this.$store.state.userInfo.userToken) this.signLogin();
    }
  },
  activated() {
    if (this.$route.query.search || this.$route.query.search === '') {
      this.serviceName = this.$route.query.search
    }
  },
  components: {
    Consult,
    Field
  }
}
</script>

<style scoped>
.tips2 {
  color: #fff;
  text-align: center;
  font-size: 12px;
  background: #254EC1;
  opacity: 0.82;
  border-radius: 4px;
  height: 25px;
  line-height: 25px;
  margin-top: 10px;
}

.van-cell {
  background-color: transparent;
}

#fbw {
  background-image: url(../../../assets/fbw/fbw2_bg.png);
  background-size: 100% auto;
  padding: 10px 10px 30px 10px;
  background-repeat: no-repeat;
  background-color: #4590ED;
}

.banner1 {
  width: 100%;
}
.box-top{
  height:50px;
  position:absolute;
  top:-10px;
  left:0;
  width:100%;
  background-image:url('../../../assets/fbw/box-bg.png');
  background-size:100% auto;
  background-repeat: no-repeat;
}
.box {
  padding:50px 20px 20px;
  background-color: #fff;
  position:relative;
  border-radius: 6px;
}

.box-title {
  background: #E8F3FC;
  border-radius: 4px;
  font-weight: bold;
  color: #218CF5;
  font-size: 14px;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 5px;
}

.button {
  background: linear-gradient(180deg, #31B2FC, #1C81F3);
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.tips {
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
}

.van-cell {
  padding-right: 0;
  padding-left: 0;
}
</style>